import React, { useEffect, useState } from 'react';

const ResultsFilter = () => {
    const [query, setQuery] = useState('')
    const handleChange = (event) => {
        let results = document.getElementsByClassName('pane')
        setQuery(event.target.value);
        if (!results) {console.log('no results found')}
        else {
            let count = results.length;
            let i = 0;
            for (i = 0; i < count; i++) {
                if(query==""|"Search coverages") {
                    results[i].style.display = "flex"
                }
                else if(results[i].textContent.includes(query)) {
                    results[i].style.display = "flex"
                }
                else {results[i].style.display = "none";}
          }
        }
    }

    return ( <input type="text" id="search_filter" name="search_filter" placeholder="Search coverages" value={query} onChange={handleChange} ></input>  );
}
 
export default ResultsFilter;