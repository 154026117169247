import React, { useState } from 'react';
import MultiStep from './MultiStep'
import Launchpad_Wordmark from '../../img/launchpad-wordmark-blue.svg'
import '../../styles/multistep-form.css'
import '../../styles/expanding-boxes.css'
import CurrentContext from '../../components/CurrentContext.js'
import ProspectContext from '../../components/ProspectContext.js'



export default function MultiStepForm (props) {
    const [tcpa, setTcpa] = useState(1)
    const [checked, setChecked] = useState(true)
    const [current, setCurrent] = useState(0)
    const value = { current, setCurrent };
    const [prospect, setProspect] = useState({})
    const updateProspect = (event) => {
        let field = event.target.name;
        let input = event.target.value;
        setProspect(
            {...prospect,
                [event.target.name]:event.target.value
            }
        );
    }
    const updateBusinessType = (event, props) => {
        setProspect(
            {...prospect,
                coverage_type:event.target.innerHTML
            }
        );
        setCurrent(3)
    }
    const updateTCPA = (event) => {
        var source321
        let idRegex = new RegExp("source321=([^;]+)")
        try {source321 = idRegex.exec(document.cookie)[1]}
        catch (err) {source321=null}
    
        var referrer321
        let idRegex2 = new RegExp("referrer321=([^;]+)")
        try {referrer321 = idRegex2.exec(document.cookie)[1]}
        catch (err) {referrer321=null}
    
        var source_code321
        let idRegex3 = new RegExp("source_code321=([^;]+)")
        try {source_code321 = idRegex3.exec(document.cookie)[1]}
        catch (err) {source_code321=351}

        var gclid
        let idRegex4 = new RegExp("gclid=([^;]+)")
        try {gclid = idRegex4.exec(document.cookie)[1]}
        catch (err) {gclid=null}

        setTcpa(!tcpa)
        if(tcpa===false){
            setProspect(
                {...prospect,
                    tcpa:null,
                    source:source321,
                    referrer:referrer321,
                    source_code:source_code321,
                    gclid:gclid
                }
            );
        }
        else {
            setProspect(
                {...prospect,
                    tcpa:1,
                    source:source321,
                    referrer:referrer321,
                    source_code:source_code321,
                    gclid:gclid
                }
            );
        }
    }

    const updateHiddenFields = () => {
        var source321
        let idRegex = new RegExp("source321=([^;]+)")
        try {source321 = idRegex.exec(document.cookie)[1]}
        catch (err) {source321=null}
    
        var referrer321
        let idRegex2 = new RegExp("referrer321=([^;]+)")
        try {referrer321 = idRegex2.exec(document.cookie)[1]}
        catch (err) {referrer321=null}
    
        var source_code321
        let idRegex3 = new RegExp("source_code321=([^;]+)")
        try {source_code321 = idRegex3.exec(document.cookie)[1]}
        catch (err) {source_code321=430}

        var gclid
        let idRegex4 = new RegExp("gclid=([^;]+)")
        try {gclid = idRegex4.exec(document.cookie)[1]}
        catch (err) {gclid=null}

        setProspect(
            {...prospect,
                source:source321,
                referrer:referrer321,
                source_code: source_code321,
                gclid: gclid
            }
        );
        console.log(prospect);
    }
    const value2 = { prospect, updateProspect, updateBusinessType, updateTCPA }

    return (
        <div id="media_quote_form">
            <div id="quote_request_form" className="quote-form">
            <div id="quote_form_header">
                <div id="form_logo"><img src={Launchpad_Wordmark} alt="Insurance321 Launchpad Word Mark" /></div>
                <h3>Best-Match Technology</h3>
            </div>
            <CurrentContext.Provider value={value}>
                <ProspectContext.Provider value={value2}>
                    <MultiStep />
                </ProspectContext.Provider>
            </CurrentContext.Provider>
            </div>
        </div>
    )
}