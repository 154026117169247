import React from 'react';
import hardhats from '../../img/multistep-icons/hardhats.svg'
import RightArrow from '../../img/right-arrow.svg'
import CoverageIcon from '../../img/multistep-icons/coverage.svg'
import BusinessIcon from '../../img/multistep-icons/business-type.svg'


const StepOne = () => {


    return ( 
        <div id="step_one">
            <div className="multistep-heading">
                <p>How would you like to shop for coverage?</p>
            </div>
            <div className="flex-box">
                <div className="flex-horizontal">
                    <div className="flex-vertical">
                            <div className="tile" id="industry_select">
                                <div className="tile-icon">
                                    <img src={BusinessIcon} alt ="hardhats icon"></img>
                                </div>
                                <div className="tile-bottom">
                                    <h4>Select by industry</h4>
                                    <img className="right-arrow" src={RightArrow} alt="right arrow" />
                                </div>
                            </div>

                        <div className="tile" id="coverage_select">
                            <div className="tile-icon">
                                <img src={CoverageIcon} alt ="hardhats icon"></img>
                            </div>
                            <div className="tile-bottom">
                                <h4>Select by coverage</h4>
                                <img className="right-arrow" src={RightArrow} alt="right arrow" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default StepOne;