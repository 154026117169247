import React from 'react';
import BusinessTypesList from '../../components/BusinessTypesList'
import MultiStepNav from './MultiStepNav';
import ResultsFilter from './ResultsFilter';

const SelectIndustry = () => {
    return (
        <div id="industry_selector">
            <p>Select a business type or industry</p>
            <ResultsFilter />
            <BusinessTypesList>
                
            </BusinessTypesList>
            <MultiStepNav nextStep={3} prevStep={0} />
        </div>
        
    );
}
 
export default SelectIndustry;