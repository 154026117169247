import React from 'react';
import CoverageTypesList from '../../components/CoverageTypesListCopy'
import MultiStepNav from './MultiStepNav';
import ResultsFilter from './ResultsFilter';

const SelectIndustry = (props) => {
    return (
        <div id="industry_selector">
            <p>Select a coverage type</p>
            <ResultsFilter />
            <CoverageTypesList nextStep={3} prevStep={0}>
                
            </CoverageTypesList>
            <MultiStepNav nextStep={3} prevStep={0}/>
        </div>
        
    );
}
 
export default SelectIndustry;