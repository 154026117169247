import React from 'react';
import '../styles/quote-comparison-table.css'
import hiscox from '../img/carrier-logos/hiscox.png'
import nationwide from '../img/carrier-logos/nationwide.png'
import homesite from '../img/carrier-logos/homesite.png'
import biberk from '../img/carrier-logos/biberk.png'
import { FaBeer, FaMapMarkerAlt, FaBuilding, FaRegCalendarAlt, FaShieldAlt } from 'react-icons/fa';
import "../styles/QuoteComparison.css"

const QuoteComparison = (props) => {
    return (
            <div id="quote_comparison">
                <div className="grid">
                    <div id="quote_header">
                        <h4>Rate Comparison</h4>
                        <div className="date right label"><FaRegCalendarAlt /> &nbsp;{props.date}</div>
                    </div>
                    
                    <div className="row column-headings">
                        <div className="column"><p className="label"><FaBuilding /> Industry:</p></div>
                        <div className="column"><p className="label"><FaMapMarkerAlt /> Location:</p></div>
                        <div className="column"><p className="label"><FaShieldAlt /> Coverage:</p></div>
                    </div>
                    <div className="row column-headings">
                        <div className="column"><p className="value">{props.industry}</p></div>
                        <div className="column"><p className="value">{props.location}</p></div>
                        <div className="column"><p className="value">{props.coverage}</p></div>
                    </div>
                </div>
                <div className="grid">
                    <div className="row">
                        <div className="column carrier-logo"><img src={hiscox} alt="insurance logo" /><p className='mobile'>Quote #1</p></div>
                        <div className="column price">{props.rate1}</div>
                    </div>
                    <div className="row">
                        <div className="column carrier-logo"><img src={nationwide} alt="insurance logo" /><p className='mobile'>Quote #2</p></div>
                        <div className="column price">{props.rate2}</div>
                    </div>
                    <div className="row">
                        <div className="column carrier-logo"><img src={homesite} alt="insurance logo" /><p className='mobile'>Quote #3</p></div>
                        <div className="column price">{props.rate3}</div>
                    </div>
                    <div className="row">
                        <div className="column carrier-logo"><img src={biberk} alt="insurance logo" /><p className='mobile'>Insurance321</p></div>
                        <div className="column price"><span className="green">{props.rate4}</span></div>
                    </div>
                </div>
                <a href="#multistep_form"><button className="start-quote" type="submit">Start Quote</button></a>
            </div>
        // </section>
    );
}
 
export default QuoteComparison;