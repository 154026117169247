import React, { useState, useContext, useEffect } from "react"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"
import TCPA from "../TCPA.js"
import User from "../User"
import MultiStepNav from "./MultiStepNav"
import ProspectContext from "../../components/ProspectContext.js"
import CurrentContext from "../../components/CurrentContext.js"
import axios from "axios"
import Session321 from "../Session321"
import { Helmet } from "react-helmet"

const StepFour = () => {
  const { prospect, updateProspect } = useContext(ProspectContext)
  const { current, setCurrent } = useContext(CurrentContext)
  const [isInProgress, seIsInProgress] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  useEffect(() => {
    let skipButton = document.getElementById("skip_button")
    if (!skipButton) {
    } else {
      skipButton.style.display = "none"
    }
  })

  const sendRequest = (userData, prospect) =>
    new Promise(async (resolve, reject) => {
      axios
        .post(
          "https://bmnwd4s4k4.execute-api.us-east-2.amazonaws.com/default/insurance-form-handler",
          userData
        )
        .then(async function (response) {
          resolve(response.data)
          // if (response.status === 200) {
          //   setCurrent(5)
          // } else {
          //   setCurrent(5)
          // }
        })
        .catch(function (error) {
          // setCurrent(5)
          reject(error)

          console.log(
            error,
            "There was an error submitting your request. Please contact us if the problem persists."
          )
        })
    })

  const onSubmit = async (e, data, updateHiddenFields) => {
    //e.preventDefault()
    try {
      const elem = document.createElement("span")
      elem.innerHTML = prospect?.coverage_type
      seIsInProgress(true)
      const userData = {
        application_form: {
          answer_values: [
            {
              code: "mqs_first_name",
              answer: prospect?.first_name,
            },
            {
              code: "mqs_last_name",
              answer: prospect?.last_name,
            },
            {
              code: "mqs_business_name",
              answer: elem.innerText,
            },
            {
              code: "mqs_phone",
              answer: prospect?.callback_number,
            },
            {
              code: "bold_penguin_gclid",
              answer: prospect?.gclid
            }
          ]
        }
      }
      const data = await sendRequest(userData, prospect)
      const isFound = data?.quoteId ? data?.quoteId.search("status 422") : -1
      if (isFound >= 0) {
        alert("Mobile number already exists !!")
        console.error("Please contact support")
      } else {
        setCurrent(5)
        navigate("/success")
      }
      seIsInProgress(false)
    } catch (error) {
      alert("Error occured !!")
      console.error("Please contact support")
      seIsInProgress(false)
    }
  }

  return (
    <div id="step_four">
      <div className="multistep-heading">
        <p>Contact Information</p>
      </div>
      <div className="flex-box">
        <div className="flex-horizontal">
          <div className="flex-vertical">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                type="text"
                name="first_name"
                placeholder="First Name*"
                value={prospect.first_name}
                {...register("first_name", {
                  required: true,
                  maxLength: 20,
                  pattern: /^[A-Za-z]+$/i,
                })}
                onChange={updateProspect}
              ></input>
              <p className="error-message">
                {errors.first_name?.type === "required" &&
                  "First name is required"}
              </p>
              <input
                type="text"
                name="last_name"
                placeholder="Last Name*"
                value={prospect.last_name}
                {...register("last_name", {
                  pattern: /\b([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+/i,
                })}
                required
                onChange={updateProspect}
              ></input>
              <p className="error-message">
                {errors.last_name && "Last name is required"}
              </p>
              <input
                type="email"
                name="email"
                placeholder="Email*"
                value={prospect.email}
                {...register("email", {
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                })}
                required
                onChange={updateProspect}
              ></input>
              <p className="error-message">
                {errors.email && "Valid email is required"}
              </p>
              <input
                type="tel"
                name="callback_number"
                placeholder="Callback Number*"
                value={prospect.callback_number}
                pattern="(?:(?:(\s*\(?([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\)?\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})"
                maxLength={14}
                minLength={10}
                required
                {...register("callback_number")}
                onChange={updateProspect}
              ></input>
              <p className="error-message">
                {errors.callback_number && "Callback number name is required"}
              </p>
              <p id="footnote">*Required field</p>
              <p id="error_message"></p>
              <User />
              <div className="disclaimer">
                <input
                  type="hidden"
                  name="tcpa"
                  {...register("tcpa")}
                  onChange={updateProspect}
                />
                <TCPA />
              </div>
              <div className="button-container">
                <button
                  className="request-quote form-submit-conversion"
                  type="submit"
                  disabled={isInProgress}
                >
                  Start Quote
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <MultiStepNav nextStep={4} prevStep={0} />
    </div>
  )
}

export default StepFour
