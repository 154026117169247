import React from 'react';
import { Link } from "gatsby"
import RightArrow from '../img/right-arrow.svg'
const CoverageTypes = () => {
    return (
        <div className="panel-container">
            <div id="coverage_types_panel">
                <h3 style={{width:'100%', paddingLeft:'0px'}}>Popular Coverage Types</h3>
                <Link to='/commercial-general-liability-insurance'><div className='pane'><p>General Liability Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/business-owners-policy'><div className='pane'><p>Business Owners Policy &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/commercial-auto-insurance'><div className='pane'><p>Commercial Auto Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/workers-compensation-insurance'><div className='pane'><p>Workers Compensation Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/professional-liability-insurance'><div className='pane'><p>Professional Liability Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/commercial-property-insurance'><div className='pane'><p>Commercial Property Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <h3 style={{width:'100%', paddingLeft:'0px'}}>Other Coverage Types</h3>
                <Link to='/liquor-liability'><div className='pane'><p>Liquor Liability &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/bailees-coverage'><div className='pane'><p>Bailees Coverage &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/business-interruption-insurance'><div className='pane'><p>Business Interruption Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/fleet-insurance'><div className='pane'><p>Fleet Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/marine-cargo-insurance'><div className='pane'><p>Marine Cargo Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/motor-truck-cargo'><div className='pane'><p>Motor Truck Cargo &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/marine-operators-legal-liability-insurance'><div className='pane'><p>Marine Operators Legal Liability Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/marine-hull-insurance'><div className='pane'><p>Marine Hull Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/inland-marine-insurance'><div className='pane'><p>Inland Marine Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/ocean-marine-insurance'><div className='pane'><p>Ocean Marine Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/corporate-event-insurance'><div className='pane'><p>Corporate Event Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/abuse-molestation-liability'><div className='pane'><p>Abuse Molestation Liability &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/towing-general-liability-insurance'><div className='pane'><p>Towing General Liability Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/garage-liability'><div className='pane'><p>Garage Liability &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/builders-risk-insurance'><div className='pane'><p>Builders Risk Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/farm-equipment-insurance'><div className='pane'><p>Farm Equipment Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/farm-business-insurance'><div className='pane'><p>Farm Business Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/food-spoilage-insurance'><div className='pane'><p>Food Spoilage Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/corporal-punishment-liability'><div className='pane'><p>Corporal Punishment Liability &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/retail-store-insurance'><div className='pane'><p>Retail Store Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/habitational-and-lessors-risk-insurance'><div className='pane'><p>Habitational And Lessors Risk Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/freight-liability'><div className='pane'><p>Freight Liability &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/commercial-flood-insurance'><div className='pane'><p>Commercial Flood Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/tools-and-equipment-insurance'><div className='pane'><p>Tools And Equipment Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/premises-liability-insurance'><div className='pane'><p>Premises Liability Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/unladen-liability'><div className='pane'><p>Unladen Liability &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/trailer-interchange-insurance'><div className='pane'><p>Trailer Interchange Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/gap-insurance'><div className='pane'><p>Gap Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                
                <Link to='/employment-practices-liability-insurance'><div className='pane'><p>Employment Practices Liability Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/directors-and-officers-insurance'><div className='pane'><p>Directors And Officers Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/ordinance-and-law-coverage'><div className='pane'><p>Ordinance And Law Coverage &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/product-liability-insurance'><div className='pane'><p>Product Liability Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/cyber-security-insurance'><div className='pane'><p>Cyber Security Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/commercial-pollution-insurance'><div className='pane'><p>Commercial Pollution Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/hired-non-owned-auto-liability'><div className='pane'><p>Hired Non Owned Auto Liability &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/business-crime-insurance'><div className='pane'><p>Business Crime Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                
                <Link to='/commercial-rental-property-insurance'><div className='pane'><p>Commercial Rental Property Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/commercial-travel-insurance'><div className='pane'><p>Commercial Travel Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/commercial-umbrella-insurance'><div className='pane'><p>Commercial Umbrella Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/equipment-rental-insurance'><div className='pane'><p>Equipment Rental Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/e-and-o-claims-made-policy'><div className='pane'><p>E And O Claims Made Policy &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/e-and-o-occurrence-policy'><div className='pane'><p>E And O Occurrence Policy &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/errors-and-omissions-insurance'><div className='pane'><p>Errors And Omissions Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/freight-insurance'><div className='pane'><p>Freight Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/on-hook-towing-insurance'><div className='pane'><p>On Hook Towing Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                
                <Link to='/uslh-insurance-workers-comp'><div className='pane'><p>Usl&amp;H Insurance Workers Comp &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/supplemental-towing-and-cleanup'><div className='pane'><p>Supplemental Towing And Cleanup &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/garage-keepers-liability'><div className='pane'><p>Garage Keepers Liability &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/shipping-carrier-liability'><div className='pane'><p>Shipping Carrier Liability &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/food-borne-illness-liability-insurance'><div className='pane'><p>Food Borne Illness Liability Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/protection-and-indemnity-insurance'><div className='pane'><p>Protection And Indemnity Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/primary-liability'><div className='pane'><p>Primary Liability &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/personal-property'><div className='pane'><p>Personal Property &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/roadside-breakdown-insurance'><div className='pane'><p>Roadside Breakdown Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/rental-reimbursement'><div className='pane'><p>Rental Reimbursement &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/passenger-accident-insurance'><div className='pane'><p>Passenger Accident Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/limited-depreciation-insurance'><div className='pane'><p>Limited Depreciation Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/bobtail-liability'><div className='pane'><p>Bobtail Liability &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/downtime-coverage'><div className='pane'><p>Downtime Coverage &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/physical-damage'><div className='pane'><p>Physical Damage &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
            </div>
        </div>
     );
}
 
export default CoverageTypes;