import React, { useContext, useEffect } from 'react';
import building_contractor_insurance from '../img/multistep-icons-green/building-contractors.svg'
import accountants_and_bookkeepers from '../img/multistep-icons-green/accountants.svg'
import roofers_insurance from '../img/multistep-icons-green/roofing.svg'
import landscaping_and_lawn_care_business_insurance from '../img/multistep-icons-green/landscaping.svg'
import food_truck_and_caterer_insurance from '../img/multistep-icons-green/food-trucks.svg'
import electrical_and_wiring_contractors_insurance from '../img/multistep-icons-green/electricians.svg'
import IT_contractors_insurance from '../img/multistep-icons-green/computer.svg'
import tow_truck_insurance from '../img/multistep-icons-green/tow-truck.svg'
import salon_spa_and_barber_shop_insurance from '../img/multistep-icons-green/barbershops.svg'
import restaurant_and_bar_insurance from '../img/multistep-icons-green/restaurant.svg'
import religious_organizations from '../img/multistep-icons-green/churches.svg'
import real_estate_agents from '../img/multistep-icons-green/real-estate.svg'
import plumbing_and_hvac_contractors_insurance from '../img/multistep-icons-green/plumbers.svg'
import photography_and_camera_equipment_insurance from '../img/multistep-icons-green/photographers.svg'
import media_and_design_agency_insurance from '../img/multistep-icons-green/media-agencies.svg'
import manufacturing_insurance from '../img/multistep-icons-green/manufacturing.svg'
import insurance_agents from '../img/multistep-icons-green/insurance-agents.svg'
import food_liability_insurance from '../img/multistep-icons-green/food.svg'
import farmers from '../img/multistep-icons-green/farmers.svg'
import film_production_insurance from '../img/multistep-icons-green/video.svg'
import excavation_and_site_preparation_contractors_insurance from '../img/multistep-icons-green/excavation.svg'
import electronics_and_appliance_store_insurance from '../img/multistep-icons-green/electronics.svg'
import daycare_insurance from '../img/multistep-icons-green/childcare-providers.svg'
import foundation_contractors_insurance from '../img/multistep-icons-green/concrete-and-foundation.svg'
import masonry_contractors_insurance from '../img/multistep-icons-green/masonry.svg'
import hotel_insurance from '../img/multistep-icons-green/hospitality.svg'
import handyman_insurance from '../img/multistep-icons-green/handyman.svg'
import framing_contractors_insurance from '../img/multistep-icons-green/framing.svg'
import cleaning_and_janitorial_business_insurance from '../img/multistep-icons-green/janitorial.svg'
import carpenter_insurance from '../img/multistep-icons-green/carpenters-liability.svg'
import commercial_construction_insurance from '../img/multistep-icons-green/commercial-construction.svg'
import professional_liability_architects from '../img/multistep-icons-green/architects.svg'
import arborist_and_tree_service_insurance from '../img/multistep-icons-green/arborists.svg'
import car_washes from '../img/multistep-icons-green/car-wash.svg'
import professional_liability_consultants from '../img/multistep-icons-green/business-consultants.svg'
import auto_dealer_insurance from '../img/multistep-icons-green/auto-dealer.svg'
import attorneys from '../img/multistep-icons-green/attorneys.svg'
import commercial_trucking_insurance from '../img/multistep-icons-green/commercial-trucking.svg'
import garage_insurance from '../img/multistep-icons-green/garages.svg'
import commercial_fleet_insurance from '../img/multistep-icons-green/fleet.svg'
import ProspectContext from '../components/ProspectContext.js'

const BusinessTypes = () => {
    const { prospect, updateBusinessType } = useContext(ProspectContext)
   
    useEffect(() => {
        let i = 0;
        let panes = document.getElementsByClassName('pane')
        if(!panes) {console.log('no panes here')}
        else {
            let count = panes.length
            while (i < count ) {
                panes[i].addEventListener('click',updateBusinessType);
                i++
            }
        }
    })

    return (
        <div className="panel-container">
            <div className="coverage-types-panel">

                <h3>Common Business Types</h3>
                <div className='pane'><img src={building_contractor_insurance} alt="icon" className="list-icon" /><p>Building Contractor Insurance</p><p className='alias'>building contractor builder contracting</p></div>
                <div className='pane'><img src={accountants_and_bookkeepers} alt="icon" className="list-icon" /><p className="display">Insurance For Accountants And Bookkeepers</p><p className='alias'>accountants bookkeepers bookkeeping accounting</p></div>
                <div className='pane'><img src={roofers_insurance} alt="icon" className="list-icon" /><p className="display">Roofers Insurance</p><p className='alias'>roof</p></div>
                <div className='pane'><img src={landscaping_and_lawn_care_business_insurance} alt="icon" className="list-icon" /><p className="display">Landscaping And Lawn Care Business Insurance</p><p className='alias'>landscaping landscape lawn care</p></div>
                <div className='pane'><img src={food_truck_and_caterer_insurance} alt="icon" className="list-icon" /><p className="display">Food Truck And Caterer Insurance</p><p className='alias'>food truck caterer catering</p></div>
                <div className='pane'><img src={electrical_and_wiring_contractors_insurance} alt="icon" className="list-icon" /><p className="display">Electrical And Wiring Contractors Insurance</p><p className='alias'>electric electrician wiring contractor</p></div>
                <div className='pane'><img src={IT_contractors_insurance} alt="icon" className="list-icon" /><p className="display">IT Contractors Insurance</p><p className='alias'>it computing compute network systems</p></div>
                <div className='pane'><img src={tow_truck_insurance} alt="icon" className="list-icon" /><p className="display">Tow Truck Insurance</p><p className='alias'>tow truck wrecker</p></div>
                <div className='pane'><img src={salon_spa_and_barber_shop_insurance} alt="icon" className="list-icon" /><p className="display">Salon Spa And Barber Shop Insurance</p><p className='alias'>salon spa barber hair beauty day spa</p></div>
                <div className='pane'><img src={restaurant_and_bar_insurance} alt="icon" className="list-icon" /><p className="display">Restaurant And Bar Insurance</p><p className='alias'>restaurant bar tavern liquor alcohol</p></div>
                <div className='pane'><img src={religious_organizations} alt="icon" className="list-icon" /><p className="display">Insurance For Religious Organizations</p><p className='alias'>religious church mosque temple religion</p></div>
                <div className='pane'><img src={real_estate_agents} alt="icon" className="list-icon" /><p className="display">Insurance For Real Estate Agents</p><p className='alias'>real estate agent agency</p></div>
                <div className='pane'><img src={plumbing_and_hvac_contractors_insurance} alt="icon" className="list-icon" /><p className="display">Plumbing And HVAC Contractors Insurance</p><p className='alias'>plumbing plumber heating cooling air conditioner hvac contractor</p></div>

                <h3>Other Business Types</h3>
                <div className='pane'><img src={farmers} alt="icon" className="list-icon" /><p className="display">Farming &amp; Agriculture</p><p className='alias'>farm crop agriculture tractor equipment</p></div>
                <div className='pane'><img src={photography_and_camera_equipment_insurance} alt="icon" className="list-icon" /><p className="display">Photography And Camera Equipment Insurance</p><p className='alias'>photography camera equipment video studio</p></div>
                <div className='pane'><img src={media_and_design_agency_insurance} alt="icon" className="list-icon" /><p className="display">Media And Design Agency Insurance</p><p className='alias'>media design graphic agency advertising studio motion</p></div>
                <div className='pane'><img src={manufacturing_insurance} alt="icon" className="list-icon" /><p className="display">Manufacturing Insurance</p><p className='alias'>manufacturing manufacture shop plant warehouse</p></div>
                <div className='pane'><img src={insurance_agents} alt="icon" className="list-icon" /><p className="display">Insurance For Insurance Agents</p><p className='alias'>insurance agent insurance agency insure</p></div>
                <div className='pane'><img src={food_liability_insurance} alt="icon" className="list-icon" /><p className="display">Food Liability Insurance</p><p className='alias'>food restaurant bar</p></div>
                <div className='pane'><img src={film_production_insurance} alt="icon" className="list-icon" /><p className="display">Film Production Insurance</p><p className='alias'>film video production produce studio motion graphics</p></div>
                <div className='pane'><img src={excavation_and_site_preparation_contractors_insurance} alt="icon" className="list-icon" /><p className="display">Excavation And Site Preparation Contractors Insurance</p><p className='alias'>excavate excavator bulldozer earthworks site prep dig basement sewer electric</p></div>
                <div className='pane'><img src={electronics_and_appliance_store_insurance} alt="icon" className="list-icon" /><p className="display">Electronics And Appliance Store Insurance</p><p className='alias'>electronics department store appliance retail</p></div>
                <div className='pane'><img src={daycare_insurance} alt="icon" className="list-icon" /><p className="display">Daycare Insurance</p><p className='alias'>daycare day care preschool head start childcare child</p></div>
                <div className='pane'><img src={foundation_contractors_insurance} alt="icon" className="list-icon" /><p className="display">Foundation Contractors Insurance</p><p className='alias'>foundation concrete contractor block mason pour cement</p></div>
                <div className='pane'><img src={masonry_contractors_insurance} alt="icon" className="list-icon" /><p className="display">Masonry Contractors Insurance</p><p className='alias'>mason masonry brick block rock</p></div>
                <div className='pane'><img src={hotel_insurance} alt="icon" className="list-icon" /><p className="display">Hotel Insurance</p><p className='alias'>hotel hospitality motel</p></div>
                <div className='pane'><img src={handyman_insurance} alt="icon" className="list-icon" /><p className="display">Handyman Insurance</p><p className='alias'>handyman handy repair technician</p></div>
                <div className='pane'><img src={framing_contractors_insurance} alt="icon" className="list-icon" /><p className="display">Framing Contractors Insurance</p><p className='alias'>framing frame carpenter carpentry</p></div>
                <div className='pane'><img src={cleaning_and_janitorial_business_insurance} alt="icon" className="list-icon" /><p className="display">Cleaning And Janitorial Business Insurance</p><p className='alias'>cleaning clean janitorial housekeeping maid</p></div>
                <div className='pane'><img src={carpenter_insurance} alt="icon" className="list-icon" /><p className="display">Carpenter Insurance</p><p className='alias'>carpenter carpentry woodworking wood cabinet shop</p></div>
                <div className='pane'><img src={commercial_construction_insurance} alt="icon" className="list-icon" /><p className="display">Commercial Construction Insurance</p><p className='alias'>commercial construction contract steel build</p></div>
                <div className='pane'><img src={professional_liability_architects} alt="icon" className="list-icon" /><p className="display">Professional Liability Insurance For Architects</p><p className='alias'>architect architecture build design</p></div>
                <div className='pane'><img src={arborist_and_tree_service_insurance} alt="icon" className="list-icon" /><p className="display">Arborist And Tree Service Insurance</p><p className='alias'>arborist tree service tree shrub landscape landscaping</p></div>
                <div className='pane'><img src={car_washes} alt="icon" className="list-icon" /><p className="display">Insurance For Car Washes</p><p className='alias'>car wash drive-through</p></div>
                <div className='pane'><img src={professional_liability_consultants} alt="icon" className="list-icon" /><p className="display">Professional Liability Insurance For Consultants</p><p className='alias'>business consultant consulting consult consultancy</p></div>
                <div className='pane'><img src={auto_dealer_insurance} alt="icon" className="list-icon" /><p className="display">Auto Dealer Insurance</p><p className='alias'>auto dealer dealership car sales </p></div>
                <div className='pane'><img src={attorneys} alt="icon" className="list-icon" /><p className="display">Insurance For Attorneys</p><p className='alias'>attorney lawyer practice law</p></div>
                <div className='pane'><img src={commercial_trucking_insurance} alt="icon" className="list-icon" /><p className="display">Commercial Trucking Insurance</p><p className='alias'>commercial trucking trucker haul logistic freight inland marine </p></div>
                <div className='pane'><img src={garage_insurance} alt="icon" className="list-icon" /><p className="display">Garage Insurance</p><p className='alias'>garage bodyshop auto repair shop automotive brake tire transmission exhaust</p></div>
                <div className='pane'><img src={commercial_fleet_insurance} alt="icon" className="list-icon" /><p className="display">Commercial Fleet Insurance</p><p className='alias'>commercial fleet truck auto car</p></div>
            </div>
        </div>
     );
}
 
export default BusinessTypes;