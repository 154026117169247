import React from 'react';
import { Link } from "gatsby"
import RightArrow from '../img/right-arrow.svg'
const CoverageTypes = () => {
    return (
        <div className="panel-container">
            <div className="coverage-types-panel">
                <h3 style={{width:'100%', paddingLeft:'0px'}}>Common Business Types</h3>
                <Link to='/building-contractor-insurance'><div className='pane'><p>Building Contractor Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/insurance-for-accountants-and-bookkeepers'><div className='pane'><p>Insurance For Accountants And Bookkeepers &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/roofers-insurance'><div className='pane'><p>Roofers Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/landscaping-and-lawn-care-business-insurance'><div className='pane'><p>Landscaping And Lawn Care Business Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/food-truck-and-caterer-insurance'><div className='pane'><p>Food Truck And Caterer Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/electrical-and-wiring-contractors-insurance'><div className='pane'><p>Electrical And Wiring Contractors Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/it-contractors-insurance'><div className='pane'><p>IT Contractors Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/tow-truck-insurance'><div className='pane'><p>Tow Truck Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/salon-spa-and-barber-shop-insurance'><div className='pane'><p>Salon Spa And Barber Shop Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/restaurant-and-bar-insurance'><div className='pane'><p>Restaurant And Bar Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/insurance-for-religious-organizations'><div className='pane'><p>Insurance For Religious Organizations &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/insurance-for-real-estate-agents'><div className='pane'><p>Insurance For Real Estate Agents &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/plumbing-and-hvac-contractors-insurance'><div className='pane'><p>Plumbing And Hvac Contractors Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <h3 style={{width:'100%', paddingLeft:'0px'}}>Other Business Types</h3>
                <Link to='/photography-and-camera-equipment-insurance'><div className='pane'><p>Photography And Camera Equipment Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/media-and-design-agency-insurance'><div className='pane'><p>Media And Design Agency Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/manufacturing-insurance'><div className='pane'><p>Manufacturing Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/insurance-for-insurance-agents'><div className='pane'><p>Insurance For Insurance Agents &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/food-liability-insurance'><div className='pane'><p>Food Liability Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/film-production-insurance'><div className='pane'><p>Film Production Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/excavation-and-site-preparation-contractors-insurance'><div className='pane'><p>Excavation And Site Preparation Contractors Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/electronics-and-appliance-store-insurance'><div className='pane'><p>Electronics And Appliance Store Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/daycare-insurance'><div className='pane'><p>Daycare Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/foundation-contractors-insurance'><div className='pane'><p>Foundation Contractors Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/masonry-contractors-insurance'><div className='pane'><p>Masonry Contractors Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/hotel-insurance'><div className='pane'><p>Hotel Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/handyman-insurance'><div className='pane'><p>Handyman Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/framing-contractors-insurance'><div className='pane'><p>Framing Contractors Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/cleaning-and-janitorial-business-insurance'><div className='pane'><p>Cleaning And Janitorial Business Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/carpenter-insurance'><div className='pane'><p>Carpenter Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/commercial-construction-insurance'><div className='pane'><p>Commercial Construction Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/professional-liability-insurance-for-architects'><div className='pane'><p>Professional Liability Insurance For Architects &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/arborist-and-tree-service-insurance'><div className='pane'><p>Arborist And Tree Service Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/insurance-for-car-washes'><div className='pane'><p>Insurance For Car Washes &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/professional-liability-insurance-for-consultants'><div className='pane'><p>Professional Liability Insurance For Consultants &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/auto-dealer-insurance'><div className='pane'><p>Auto Dealer Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/insurance-for-attorneys'><div className='pane'><p>Insurance For Attorneys &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/commercial-trucking-insurance'><div className='pane'><p>Commercial Trucking Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/garage-insurance'><div className='pane'><p>Garage Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
                <Link to='/commercial-fleet-insurance'><div className='pane'><p>Commercial Fleet Insurance &nbsp; </p><img src={RightArrow} alt="right arrow" /></div></Link>
            </div>
        </div>
     );
}
 
export default CoverageTypes;