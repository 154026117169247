import React, { useContext, useEffect, useState } from 'react';
import CurrentContext from '../../components/CurrentContext.js'
import StepOne from './StepOne.js'
import StepFour from './StepFour.js'
import SelectIndustry from './SelectIndustry';
import SelectCoverage from './SelectCoverage'
import StepThree from './StepThree.js';
import SubmissionConfirmation from './SubmissionConfirmation.js';
import { FacebookMessengerShareButton } from 'react-share';

const MultiStep = (props) => {
    const {current, setCurrent} = useContext(CurrentContext)

    const steps = [
        { component: <StepOne /> },
        { component: <SelectIndustry />},
        { component: <SelectCoverage />},
        { component: <StepThree />},
        { component: <StepFour />},
        { component: <SubmissionConfirmation /> }
      ]

    useEffect(() => {
        const industryEl = document.getElementById('industry_select')
        if(!industryEl) {
            //console.log('no industry element. on another step')
        }
        else {industryEl.addEventListener('click', industry)}

        const coverageEl = document.getElementById('coverage_select')
        if(!coverageEl) {
            //console.log('no industry element. on another step')
        }
        else {coverageEl.addEventListener('click', coverage)}
    })

    const nextStep = () => {
        setCurrent(1)
    }
    const prevStep = () => {
        setCurrent(current = current - 1)
    }

    const restart = () => {
        setCurrent(0)
    }

    const industry = () => {
        setCurrent(1)
    }

    const coverage = () => {
        setCurrent(2)
    }
    
    return (
            <div className="current-step">
                {steps[current].component}
            </div>
     );
}
 
export default MultiStep;