import React from "react"
import RocketMark from "../../img/rocketmark.svg"
import Launchpad_Wordmark from "../../img/launchpad-wordmark-blue.svg"
const SubmissionConfirmation = () => {
  return (
    <div id="submission_confirmation">
      <div className="multistep-heading">
        <h3>Thank You!</h3>
      </div>
      <div id="rocketmark">
        <img src={RocketMark} alt="rocketmark" />
      </div>
      <h2>Launch Successful</h2>
      <p>We have received your submission.</p>
      <p>
        An agent will call soon at the number you provided to confirm your
        business details and review your coverage options.
      </p>
      <h2>In a hurry?</h2>
      <p>
        Give us a call at:{" "}
        <span className="upsize subtle-green call-conversion">
          <strong>
            <a href="tel:+1-234-230-6321">(234) 230-6321</a>
          </strong>
        </span>{" "}
        ...and an agent will process your request right away.
      </p>
    </div>
  )
}

export default SubmissionConfirmation
