import React, { useEffect, useState } from 'react';
import CoverageTypes from '../components/CoverageTypes';
import BusinessTypes from '../components/BusinessTypes'
import nwArrow from '../img/nw-arrow.svg'
import CloseButton from '../img/close-x-darkblue.svg'
import MobileArrow from '../img/up-arrow-touch.svg'
import '../styles/expanding-boxes.css'
import down_arrow from '../img/down_arrow.svg'

const BoxExpander = () => {
        const [state, setState] = useState('collapsed');
        const [boxAction, setBoxAction ] = useState();
        
        useEffect(() => {
            const leftBox = document.getElementById('left_box');
            let closeX = document.getElementById('left_box').getElementsByClassName('close-X')[0];
            let openArrow = document.getElementById('left_box').getElementsByClassName('open-arrow')[0];
            let containerWidth;
            const collapseLeftBox = (containerWidth) => {
                if (window.innerWidth > 768) {
                    console.log('first block executed')
                    const leftBox = document.getElementById('left_box');
                    console.log('tablet')
                    //containerWidth = '210px';
                    //leftBox.style.bottom = containerWidth;
                    leftBox.style.height = '60px';
                    leftBox.style.width = '50%';
                }
                else if (window.innerWidth < 768) {
                    const leftBox = document.getElementById('left_box');
                    console.log('mobile')
                    containerWidth = '50%';
                    leftBox.style.bottom = '0px';
                    leftBox.style.left = '0px';
                    leftBox.style.height = '60px';
                    leftBox.style.width = '50%';
                }
                else {
                    const leftBox = document.getElementById('left_box');
                    console.log('desktop');
                    containerWidth = '210px'
                    leftBox.style.bottom = containerWidth;
                }
                let leftBox = document.getElementById('left_box');
                document.getElementsByClassName('close-X')[0].style.opacity = '0.0';
                leftBox.style.transition = 'all .5s';
                leftBox.style.zIndex = '1';
                leftBox.style.color = '#fff';
                document.getElementById('coverage_types_panel').style.display = 'none';
                document.getElementsByClassName('open-arrow')[0].style.display = 'inline-block';
                leftBox.getElementsByTagName('h5')[0].style.display = 'flex';
                setState('collapsed');
            }
    
            const expandLeftBox = () =>{
                let leftBox = document.getElementById('left_box');
                var boxContainer = document.getElementById('hero_right');
                var containerHeight = boxContainer.offsetHeight;
                var containerWidth = boxContainer.offsetWidth;
                leftBox.style.transition = 'all .5s';
                leftBox.style.bottom = '0px';
                
                leftBox.style.zIndex = '3';
                leftBox.style.height = containerHeight+'px';
                leftBox.style.width = containerWidth+'px';
                leftBox.style.color = '#000d33';
                document.getElementById('coverage_types_panel').style.display = 'flex';
                document.getElementsByClassName('close-X')[0].style.opacity = '0.4';
                document.getElementsByClassName('open-arrow')[0].style.display = 'none';
                leftBox.getElementsByTagName('h5')[0].style.display = 'none';
                closeX.addEventListener('click', collapseLeftBox);
                setState('expanded')
            }
            closeX.addEventListener('click', collapseLeftBox);
            openArrow.addEventListener('click', expandLeftBox, {once:true});
        });

        useEffect(() => {
            let rightBox = document.getElementById('right_box');
            let closeX = document.getElementById('right_box').getElementsByClassName('close-X')[0];
            let openArrow = document.getElementById('right_box').getElementsByClassName('open-arrow')[0];
            let containerWidth;
            const collapseRightBox = (containerWidth) => {
                if (window.innerWidth > 768) {
                    const rightBox = document.getElementById('right_box');
                    containerWidth = '50%';
                    rightBox.style.bottom = containerWidth;
                    rightBox.style.left = '50%';
                    rightBox.style.height = '60px';
                    rightBox.style.width = containerWidth;
                }
                else if (window.innerWidth < 768) {
                    const rightBox = document.getElementById('right_box');
                    console.log('mobile')
                    containerWidth = '100%';
                    rightBox.style.left = '50%';
                    rightBox.style.height = '60px';
                    rightBox.style.width = '50%';
                }
                else {
                    const rightBox = document.getElementById('right_box');
                    console.log('desktop');
                    containerWidth = '50%'
                    //rightBox.style.top = containerWidth;
                }
                let rightBox = document.getElementById('right_box');
                let closeX = document.getElementById('right_box').getElementsByClassName('close-X')[0].style.opacity = '0.0';
                rightBox.style.transition = 'all .5s';
                rightBox.style.bottom = '0px';
                rightBox.style.zIndex = '1';
                rightBox.style.color = '#fff';
                document.getElementById('right_box').getElementsByClassName('coverage-types-panel')[0].style.display = 'none';
                document.getElementById('right_box').getElementsByClassName('open-arrow')[0].style.display = 'inline-block';
                rightBox.getElementsByTagName('h5')[0].style.display = 'flex';
                setState('collapsed');
            }
        
            const expandRightBox = () =>{
                let rightBox = document.getElementById('right_box');
                var boxContainer = document.getElementById('hero_right');
                var containerHeight = boxContainer.offsetHeight;
                var containerWidth = boxContainer.offsetWidth;
                rightBox.style.transition = 'all .5s';
                rightBox.style.bottom = '0px';
                rightBox.style.left = '0px';
                rightBox.style.zIndex = '3';
                rightBox.style.height = containerHeight+'px';
                rightBox.style.width = containerWidth+'px';
                rightBox.style.color = '#000d33';
                document.getElementById('right_box').getElementsByClassName('coverage-types-panel')[0].style.display = 'flex';
                let closeX = document.getElementById('right_box').getElementsByClassName('close-X')[0].style.opacity = '0.4';
                document.getElementById('right_box').getElementsByClassName('open-arrow')[0].style.display = 'none';
                rightBox.getElementsByTagName('h5')[0].style.display = 'none';
                setState('expanded')
            }
            closeX.addEventListener('click', collapseRightBox);
            openArrow.addEventListener('click', expandRightBox, {once:true});
        });
        
    return (                         
        <div id="search_box">
            <div id="left_box" className="expanding-box">
                <div className="close-X"><img src={CloseButton} alt="Close button" width="50px" /></div>
                <h5>Browse Coverages</h5>
                <div className="open-arrow"><img src={down_arrow} alt="northwest arrow"></img></div>
                <div className="mobile-open-arrow"><img src={down_arrow} alt="up open arrow" /></div>
                <CoverageTypes />
            </div>
            <div id="right_box" className="expanding-box">
                <div className="close-X"><img src={CloseButton} alt="Close button" width="50px" /></div>
                <h5>Browse Industries</h5>
                <div className="open-arrow"><img src={down_arrow} alt="northwest arrow"></img></div>
                <div className="mobile-open-arrow"><img src={down_arrow} alt="up open arrow" /></div>
                <BusinessTypes />
            </div>
        </div>
    );
}
 
export default BoxExpander;