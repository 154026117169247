import React, { useContext, useEffect } from 'react';
import ProspectContext from '../components/ProspectContext.js'
import coverage from '../img/multistep-icons/coverage_icons/business-type.svg'
import professional_liability from '../img/multistep-icons/coverage_icons/professional_liability.svg'
import workers_compensation from '../img/multistep-icons/coverage_icons/workers_compensation.svg'
import commercial_trucking_insurance from '../img/multistep-icons/coverage_icons/commercial-trucking.svg'
import daycare_insurance from '../img/multistep-icons/coverage_icons/childcare-providers.svg'
import tow_truck_insurance from '../img/multistep-icons/coverage_icons/tow-truck.svg'
import carpenter from '../img/multistep-icons/coverage_icons/carpenter.svg'
import attorneys from '../img/multistep-icons/coverage_icons/attorneys.svg'
import real_estate from '../img/multistep-icons/coverage_icons/real-estate.svg'
import bailees_coverage from "../img/multistep-icons/coverage_icons/bailees-coverage.svg"
import builders_risk from "../img/multistep-icons/coverage_icons/builders-risk-insurance.svg"
import business_crime from "../img/multistep-icons/coverage_icons/business-crime-insurance.svg"
import business_interruption from "../img/multistep-icons/coverage_icons/business-interruption-insurance.svg"
import business_owners_policy from "../img/multistep-icons/coverage_icons/business-owners-policy.svg"
import commercial_auto from "../img/multistep-icons/coverage_icons/commercial-auto-insurance.svg"
import commercial_flood from "../img/multistep-icons/coverage_icons/commercial-flood-insurance.svg"
import commercial_general_liability from "../img/multistep-icons/coverage_icons/commercial-general-liability-insurance.svg"
import commercial_pollution from "../img/multistep-icons/coverage_icons/commercial-pollution-insurance.svg"
import commercial_property from "../img/multistep-icons/coverage_icons/commercial-property-insurance.svg"
import commercial_rental_property from "../img/multistep-icons/coverage_icons/commercial-rental-property-insurance.svg"
import commercial_travel_coverage from "../img/multistep-icons/coverage_icons/commercial-travel-insurance-coverage.svg"
import commercial_trucking_bobtail_liability from "../img/multistep-icons/coverage_icons/commercial-trucking-bobtail-liability-insurance.svg"
import commercial_trucking_physical_damage from "../img/multistep-icons/coverage_icons/commercial-trucking-physical-damage-insurance.svg"
import commercial_trucking_primary_liability from "../img/multistep-icons/coverage_icons/commercial-trucking-primary-liability-insurance.svg"
import commercial_trucking_unladen_liability from "../img/multistep-icons/coverage_icons/commercial-trucking-unladen-liability-insurance.svg"
import commercial_umbrella from "../img/multistep-icons/coverage_icons/commercial-umbrella-insurance.svg"
import corporal_punishment_liability from "../img/multistep-icons/coverage_icons/corporal-punishment-liability.svg"
import corporate_event from "../img/multistep-icons/coverage_icons/corporate-event-insurance.svg"
import cyber_liability_data_security from "../img/multistep-icons/coverage_icons/cyber-liability-data-security-insurance.svg"
import directors_officers from "../img/multistep-icons/coverage_icons/directors-officers-insurance.svg"
import employment_practices_liability from "../img/multistep-icons/coverage_icons/employment-practices-liability-insurance.svg"
import equipment_rental from "../img/multistep-icons/coverage_icons/equipment-rental-insurance.svg"
import errors_omissions_claims_made_policy from "../img/multistep-icons/coverage_icons/errors-omissions-insurance-claims-made-policy.svg"
import errors_omissions_coverage from "../img/multistep-icons/coverage_icons/errors-omissions-insurance-coverage.svg"
import errors_omissions_occurrence_policy from "../img/multistep-icons/coverage_icons/errors-omissions-insurance-occurrence-policy.svg"
import farm_equipment from "../img/multistep-icons/coverage_icons/farm-equipment-insurance.svg"
import farm_ranch_business from "../img/multistep-icons/coverage_icons/farm-ranch-business-insurance.svg"
import fleet from "../img/multistep-icons/coverage_icons/fleet-insurance.svg"
import food_borne_Illness_liability from "../img/multistep-icons/coverage_icons/food-borne-Illness-liability-insurance.svg"
import food_spoilage from "../img/multistep-icons/coverage_icons/food-spoilage-insurance.svg"
import freight from "../img/multistep-icons/coverage_icons/freight-insurance.svg"
import freight_liability from "../img/multistep-icons/coverage_icons/freight-liability-insurance.svg"
import garage from "../img/multistep-icons/coverage_icons/garage-insurance.svg"
import garage_keepers_liability_insurace from "../img/multistep-icons/coverage_icons/garage-keepers-liability-insurace.svg"
import habitational_lessors_risk from "../img/multistep-icons/coverage_icons/habitational-lessors-risk-insurance.svg"
import hired_non_owned_auto from "../img/multistep-icons/coverage_icons/hired-non-owned-auto-insurance.svg"
import inland_marine from "../img/multistep-icons/coverage_icons/inland-marine-insurance.svg"
import insurance_coverage_for_retail_stores from "../img/multistep-icons/coverage_icons/insurance-coverage-for-retail-stores.svg"
import liquor_liability from "../img/multistep-icons/coverage_icons/liquor-liability-insurance.svg"
import marine_hull_commercial_watercraft from "../img/multistep-icons/coverage_icons/marine-hull-commercial-watercraft-insurance.svg"
import marine_operators_legal_liability from "../img/multistep-icons/coverage_icons/marine-operators-legal-liability.svg"
import ocean_marine_cargo from "../img/multistep-icons/coverage_icons/ocean-marine-cargo-insurance.svg"
import ocean_marine from "../img/multistep-icons/coverage_icons/ocean-marine-insurance.svg"
import protection_indemnity_marine_liability from "../img/multistep-icons/coverage_icons/protection-indemnity-insurance-marine-liability.svg"
import shipping_carrier_liability from "../img/multistep-icons/coverage_icons/shipping-carrier-liability-insurance.svg"
import usl_h_workers_comp_on_water from "../img/multistep-icons/coverage_icons/usl-h-insurance-workers-comp-on-water.svg"
import tow_truck from "../img/multistep-icons/coverage_icons/tow-truck.svg"
import product_liability from "../img/multistep-icons/coverage_icons/product-liability.svg"
import general_liablity from "../img/multistep-icons/coverage_icons/general-liability.svg"
import limited_depreciation from "../img/multistep-icons/coverage_icons/limited-depreciation.svg"
import breakdown from "../img/multistep-icons/coverage_icons/roadside-breakdown-trucking.svg"
import rental_reimbursement from "../img/multistep-icons/coverage_icons/rental-reimbursement.svg"

const CoverageTypes = (props) => {
    const { prospect, updateBusinessType } = useContext(ProspectContext)
   
    useEffect(() => {
        let i = 0;
        let panes = document.getElementsByClassName('pane')
        if(!panes) {console.log('no panes here')}
        else {
            let count = panes.length
            while (i < count ) {
                panes[i].addEventListener('click',updateBusinessType);
                i++
            }
        }
    })

    return (
        <div id="panel_container">
            <div id="coverage_types_panel">
                <h3 style={{width:'100%', paddingLeft:'0px'}}>Popular Coverage Types</h3>

                <div className='pane'><img src={general_liablity} alt="icon" className="list-icon" /><p>General Liability Insurance</p><p className='alias'></p><p className='alias'>general liability</p></div>
                <div className='pane'><img src={business_owners_policy} alt="icon" className="list-icon" /><p>Business Owners Policy</p><p className='alias'>bop BOP business owner owners owner's policy</p></div>
                <div className='pane'><img src={commercial_auto} alt="icon" className="list-icon" /><p>Commercial Auto Insurance</p><p className='alias'>commercial auto company car fleet</p></div>
                <div className='pane'><img src={workers_compensation} alt="icon" className="list-icon" /><p>Workers Compensation Insurance</p><p className='alias'>worker worker's workers compensation</p></div>
                <div className='pane'><img src={professional_liability} alt="icon" className="list-icon" /><p>Professional Liability Insurance</p><p className='alias'>professional liability malpractice negligence</p></div>
                <div className='pane'><img src={commercial_property} alt="icon" className="list-icon" /><p>Commercial Property Insurance</p><p className='alias'>commercial property liability accident</p></div>

                <h3 style={{width:'100%', paddingLeft:'0px'}}>Other Coverage Types</h3>
                <div className='pane'><img src={liquor_liability} alt="icon" className="list-icon" /><p>Liquor Liability</p><p className='alias'>liquor alcohol drink bar tavern restaurant cafe</p></div>
                <div className='pane'><img src={bailees_coverage} alt="icon" className="list-icon" /><p>Bailees Coverage</p><p className='alias'>bailee bailee's custodian</p></div>
                <div className='pane'><img src={business_interruption} alt="icon" className="list-icon" /><p>Business Interruption Insurance</p><p className='alias'>business interruption covid quarantine</p></div>
                <div className='pane'><img src={fleet} alt="icon" className="list-icon" /><p>Fleet Insurance</p><p className='alias'>fleet auto trucking logistics car</p></div>
                <div className='pane'><img src={ocean_marine_cargo} alt="icon" className="list-icon" /><p>Marine Cargo Insurance</p><p className='alias'>marine ocean cargo freight ship shipping</p></div>
                <div className='pane'><img src={freight} alt="trucking icon" className="list-icon" /><p>Motor Truck Cargo</p><p className='alias'>truck cargo freight ship shipping inland marine</p></div>
                <div className='pane'><img src={ocean_marine_cargo} alt="icon" className="list-icon" /><p>Marine Operators Legal Liability Insurance</p><p className='alias'>marine ocean ship liability</p></div>
                <div className='pane'><img src={marine_hull_commercial_watercraft} alt="icon" className="list-icon" /><p>Marine Hull Insurance</p><p className='alias'>marine hull boat ship cargo</p></div>
                <div className='pane'><img src={inland_marine} alt="icon" className="list-icon" /><p>Inland Marine Insurance</p><p className='alias'>inland marine ship cargo goods freight</p></div>
                <div className='pane'><img src={ocean_marine} alt="icon" className="list-icon" /><p>Ocean Marine Insurance</p><p className='alias'>ocean marine cargo freight ship</p></div>
                <div className='pane'><img src={corporate_event} alt="icon" className="list-icon" /><p>Corporate Event Insurance</p><p className='alias'>corporate event office party offsite retreat</p></div>
                <div className='pane'><img src={corporal_punishment_liability} alt="icon" className="list-icon" /><p>Abuse Molestation Liability</p><p className='alias'>child abuse molestation liability daycare church school</p></div>
                <div className='pane'><img src={tow_truck_insurance} alt="icon" className="list-icon" /><p>Towing General Liability Insurance</p><p className='alias'>tow towing general liability bailee</p></div>
                <div className='pane'><img src={garage_keepers_liability_insurace} alt="icon" className="list-icon" /><p>Garage Liability</p><p className='alias'>garage auto repair bailee body shop</p></div>
                <div className='pane'><img src={builders_risk} alt="icon" className="list-icon" /><p>Builders Risk Insurance</p><p className='alias'>build building construction builders risk liability</p></div>
                <div className='pane'><img src={farm_equipment} alt="icon" className="list-icon" /><p>Farm Equipment Insurance</p><p className='alias'>farmer farm equipment agriculture tractor combine implement repair</p></div>
                <div className='pane'><img src={farm_ranch_business} alt="icon" className="list-icon" /><p>Farm Business Insurance</p><p className='alias'>farm business agriculture</p></div>
                <div className='pane'><img src={food_spoilage} alt="icon" className="list-icon" /><p>Food Spoilage Insurance</p><p className='alias'>food spoilage spoil rotten poisoning liability restaurant freezer refrigerator</p></div>
                <div className='pane'><img src={corporal_punishment_liability} alt="icon" className="list-icon" /><p>Corporal Punishment Liability</p><p className='alias'>corporal punishment liability spanking discipline</p></div>
                <div className='pane'><img src={insurance_coverage_for_retail_stores} alt="icon" className="list-icon" /><p>Retail Store Insurance</p><p className='alias'>retail store shop retailer </p></div>
                <div className='pane'><img src={habitational_lessors_risk} alt="icon" className="list-icon" /><p>Habitational And Lessors Risk Insurance</p><p className='alias'>habitational risk lessor lease rent renter</p></div>
                <div className='pane'><img src={freight_liability} alt="icon" className="list-icon" /><p>Freight Liability</p><p className='alias'>freight inland marine cargo ship</p></div>
                <div className='pane'><img src={commercial_flood} alt="icon" className="list-icon" /><p>Commercial Flood Insurance</p><p className='alias'></p></div>
                <div className='pane'><img src={carpenter} alt="icon" className="list-icon" /><p>Tools And Equipment Insurance</p><p className='alias'>tools equipment jobsite contractor</p></div>
                <div className='pane'><img src={commercial_property} alt="icon" className="list-icon" /><p>Premises Liability Insurance</p><p className='alias'>premises property liability</p></div>
                <div className='pane'><img src={commercial_trucking_unladen_liability} alt="icon" className="list-icon" /><p>Unladen Liability</p><p className='alias'>bobtail bob tail</p></div>
                <div className='pane'><img src={commercial_trucking_physical_damage} alt="icon" className="list-icon" /><p>Trailer Interchange Insurance</p><p className='alias'>trailer interchange</p></div>
                <div className='pane'><img src={commercial_auto} alt="icon" className="list-icon" /><p>Gap Insurance</p><p className='alias'>gap lease</p></div>    
                <div className='pane'><img src={employment_practices_liability} alt="icon" className="list-icon" /><p>Employment Practices Liability Insurance</p><p className='alias'>employment practices employer</p></div>
                <div className='pane'><img src={directors_officers} alt="icon" className="list-icon" /><p>Directors And Officers Insurance</p><p className='alias'>directors officer executive key man</p></div>
                <div className='pane'><img src={attorneys} alt="icon" className="list-icon" /><p>Ordinance And Law Coverage</p><p className='alias'>ordinance law legal regulation</p></div>
                <div className='pane'><img src={product_liability} alt="icon" className="list-icon" /><p>Product Liability Insurance</p><p className='alias'>product liability</p></div>
                <div className='pane'><img src={cyber_liability_data_security} alt="icon" className="list-icon" /><p>Cyber Security Insurance</p><p className='alias'>cybersecurity cyber security data IT it network hack malware ransom</p></div>
                <div className='pane'><img src={commercial_pollution} alt="icon" className="list-icon" /><p>Commercial Pollution Insurance</p><p className='alias'>commercial pollution epa EPA water </p></div>
                <div className='pane'><img src={hired_non_owned_auto} alt="icon" className="list-icon" /><p>Hired Non Owned Auto Liability</p><p className='alias'>auto car rent rental</p></div>
                <div className='pane'><img src={business_crime} alt="icon" className="list-icon" /><p>Business Crime Insurance</p><p className='alias'>business crime corporate</p></div>
                <div className='pane'><img src={commercial_rental_property} alt="icon" className="list-icon" /><p>Commercial Rental Property Insurance</p><p className='alias'>commercial rental property</p></div>
                <div className='pane'><img src={commercial_travel_coverage} alt="icon" className="list-icon" /><p>Commercial Travel Insurance</p><p className='alias'>commercial travel insurance</p></div>
                <div className='pane'><img src={commercial_umbrella} alt="icon" className="list-icon" /><p>Commercial Umbrella Insurance</p><p className='alias'>commercial umbrella insurance blanket professional business</p></div>
                <div className='pane'><img src={equipment_rental} alt="icon" className="list-icon" /><p>Equipment Rental Insurance</p><p className='alias'>equipment rental lease</p></div>
                <div className='pane'><img src={errors_omissions_claims_made_policy} alt="icon" className="list-icon" /><p>E And O Claims Made Policy</p><p className='alias'>errors omissions e and o claims made policy</p></div>
                <div className='pane'><img src={errors_omissions_occurrence_policy} alt="icon" className="list-icon" /><p>E And O Occurrence Policy</p><p className='alias'>errors omissions e and o occurrence policy</p></div>
                <div className='pane'><img src={errors_omissions_coverage} alt="icon" className="list-icon" /><p>Errors And Omissions Insurance</p><p className='alias'>e and o e&amp;o E&amp;O errors omissions e and o occurrence policy</p></div>
                <div className='pane'><img src={freight} alt="icon" className="list-icon" /><p>Freight Insurance</p><p className='alias'>freight cargo inland marine ship shipping</p></div>
                <div className='pane'><img src={tow_truck_insurance} alt="icon" className="list-icon" /><p>On Hook Towing Insurance</p><p className='alias'>on-hook on hook towing insurance tow truck</p></div>
                <div className='pane'><img src={usl_h_workers_comp_on_water} alt="icon" className="list-icon" /><p>USL&amp;H Insurance Workers Comp</p><p className='alias'>usl&amp;h longshoremen united states longshore harbor workers compensation</p></div>
                <div className='pane'><img src={tow_truck_insurance} alt="icon" className="list-icon" /><p>Supplemental Towing And Cleanup</p><p className='alias'>supplemental towing cleanup accident</p></div>
                <div className='pane'><img src={garage_keepers_liability_insurace} alt="icon" className="list-icon" /><p>Garage Keepers Liability</p><p className='alias'>garage keeper's keepers liability bailee</p></div>
                <div className='pane'><img src={shipping_carrier_liability} alt="icon" className="list-icon" /><p>Shipping Carrier Liability</p><p className='alias'>freight cargo inland marine ship shipping shipping carrier logistic trucking</p></div>
                <div className='pane'><img src={food_borne_Illness_liability} alt="icon" className="list-icon" /><p>Food Borne Illness Liability Insurance</p><p className='alias'>food borne illness food poisoning spoil coli salmonella campylobacter shigella listeria botulism enteric viruses</p></div>
                <div className='pane'><img src={protection_indemnity_marine_liability} alt="icon" className="list-icon" /><p>Protection And Indemnity Insurance</p><p className='alias'></p></div>
                <div className='pane'><img src={commercial_trucking_primary_liability} alt="icon" className="list-icon" /><p>Primary Liability</p><p className='alias'>primary liability commercial trucking truck</p></div>
                <div className='pane'><img src={commercial_trucking_insurance} alt="icon" className="list-icon" /><p>Personal Property</p><p className='alias'>personal property</p></div>
                <div className='pane'><img src={breakdown} alt="icon" className="list-icon" /><p>Roadside Breakdown Insurance</p><p className='alias'>roadside breakdown auto repair fleet truck tow</p></div>
                <div className='pane'><img src={rental_reimbursement} alt="icon" className="list-icon" /><p>Rental Reimbursement</p><p className='alias'>rental reimbursement</p></div>
                <div className='pane'><img src={workers_compensation} alt="icon" className="list-icon" /><p>Passenger Accident Insurance</p><p className='alias'>passenger accident auto</p></div>
                <div className='pane'><img src={limited_depreciation} alt="icon" className="list-icon" /><p>Limited Depreciation Insurance</p><p className='alias'>limited depreciation</p></div>
                <div className='pane'><img src={commercial_trucking_bobtail_liability} alt="icon" className="list-icon" /><p>Bobtail Liability</p><p className='alias'>bobtail bob tail unladen trailer</p></div>
                <div className='pane'><img src={commercial_trucking_unladen_liability} alt="icon" className="list-icon" /><p>Downtime Coverage</p><p className='alias'>downtime coverage down time</p></div>
                <div className='pane'><img src={commercial_trucking_physical_damage} alt="icon" className="list-icon" /><p>Physical Damage</p><p className='alias'>physical damage trucking</p></div>
            </div>
        </div>
     );
}
 
export default CoverageTypes;