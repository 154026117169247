import React, { useState, useContext, useEffect } from 'react';
import hardhats from '../../img/multistep-icons/hardhats.svg'
import TCPA from '../TCPA.js'
import User from '../User';
import MultiStepNav from './MultiStepNav';
import ProspectContext from '../../components/ProspectContext.js'

const StepThree = () => {
    const { prospect, updateProspect } = useContext(ProspectContext)
    const handleChange = () => {
        document.querySelector("#skip_button").textContent='Next';
    }

    return (
        <div id="step_three">
            <div className="multistep-heading">
                <p>Business Information</p>
            </div>
            <div className="flex-box">
                <div className="flex-horizontal">
                    <div className="flex-vertical">
                        <input type="text"  name="business_name" placeholder="Business Name" value={prospect.business_name} onChange={updateProspect, handleChange} required></input>
                        <input type="text"  name="zip_code" placeholder="Business Zip Code" value={prospect.zip_code} onChange={updateProspect} maxLength={5} minLength={5}></input>
                        <input type="text"  name="employee_count" placeholder="Number of Employees (full and part-time)" value={prospect.employee_count} ></input>
                    </div>
                </div>
            </div>
            <MultiStepNav nextStep={4} prevStep={0}/>
        </div>
        
     );
}
 
export default StepThree;