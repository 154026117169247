import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import QuoteComparison from '../components/QuoteComparison.js'
import '../styles/quote-comparison-table.css'

const QuoteCarousel = () => {
    return (
        <div id='carousel'>
            <Carousel>
                <Carousel.Item interval={4000}>
                    <QuoteComparison industry='Construction' location='GA' coverage='General Liability' date='5/7/2021' rate1='$784' rate4='$615' rate3='$1098' rate2='$1119' highlight={4} />
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                    <QuoteComparison industry='Bus. Products' location='TX' coverage='BOP' date='5/7/2021' rate1='$4210' rate4='$868' rate3='$1736' rate2='$1015' />
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                    <QuoteComparison industry='Painting' location='PA' coverage='Workers Comp.' date='5/10/2021' rate1='$10,911' rate4='$4468' rate3='$7404' rate2='$9131'/>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                    <QuoteComparison industry='Landscaping' location='GA' coverage='General Liability' date='5/13/2021' rate1='$2498' rate4='$502' rate3='$3034' rate2='Declined Coverage'/>
                </Carousel.Item>
            </Carousel>
        </div>

     );
}
 
export default QuoteCarousel;